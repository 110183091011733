<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        persistent
        max-width="1000px"
    >
        <v-card>
            <v-toolbar class="informational-modal" elevation="0">
                <v-toolbar-title class="text--h2">Edit Automated Shifts</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form
                    ref="editAutomatedShiftForm"
                    :lazy-validation="false"
                >
                    <v-row class="mt-5 row-min-width">
                        <v-col cols="12" md="7">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <p class="text--h4">
                                            Shift Time
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="ds-modal-input-container">
                                        <v-autocomplete
                                            v-model="selectedShiftTimeId"
                                            label="Shift Time Preset"
                                            :items="shiftTimes"
                                            :loading="shiftTimesLoading"
                                            :disabled="shiftTimesLoading"
                                            item-value="_id"
                                            item-text="label"
                                            clearable
                                            outlined
                                            @change="onChangeShiftTime"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-icon color="primary">mdi-clock-outline</v-icon>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" :md="true" class="ds-modal-input-container">
                                        <v-dialog
                                            ref="timePickerDialog"
                                            v-model="timePickerModal"
                                            :return-value.sync="shiftTime.startTime"
                                            persistent
                                            width="290px"
                                            :key="startTimeKey"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    name="shift-time-start-time"
                                                    label="Start Time"
                                                    v-on="on"
                                                    :value="formattedStartTime"
                                                    :error-messages="api.hasError('start_time')"
                                                    :rules="rules.required"
                                                    class="ds-modal-input"
                                                    autocomplete="off"
                                                    outlined
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="timePickerModal"
                                                v-model="shiftTime.startTime"
                                                full-width
                                                ampm-in-title
                                                scrollable
                                                color="primary"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="timePickerModal = false">Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="onConfirmStartTime"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" md="5" class="ds-modal-input-container">
                                        <time-input
                                            :key="durationKey"
                                            name="shift-time-duration"
                                            clearable
                                            controls
                                            required
                                            outlined
                                            label="Duration"
                                            placeholder="HH:MM"
                                            hint="Max: 36 hrs"
                                            :error-messages="api.hasError('duration')"
                                            v-model="shiftTime.duration"
                                            :min="60"
                                            :max="2160"
                                            :mask-tokens="customTokens"
                                            @fieldInput="onChangeDuration"
                                        ></time-input>
                                    </v-col>
                                    <v-col cols="12" :md="true" class="ds-modal-input-container">
                                        <v-text-field
                                            name="shift-time-end-time"
                                            label="End Time"
                                            class="ds-modal-input"
                                            autocomplete="off"
                                            outlined
                                            :value="end_time"
                                            readonly
                                            :hint="end_time_hint"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="5" :style="rhsContainerStyle">
                            <div class="d-flex justify-center align-center" style="height: 100%; width: 100%;">
                                <p class="text--paragraph text-center">
                                    Koroid does not allow edits to the Job Titles for multiple Automated Shifts at this
                                    time.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="onClose"
                    :disabled="isEditing"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    @click="onSave"
                    :loading="isEditing"
                    :disabled="!isDirty"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <unsaved-changes-dialog ref="unsavedChangesDialogRef"/>
    </v-dialog>
</template>

<script>
import TimeInput from "../../components/common/TimeInput/TimeInput";
import UnsavedChangesDialog from "../../components/modals/UnsavedChangesDialog";
import validationRules from "../../lib/mixins/validationRules"

export default {
    name: "EditShiftModal",
    components: {TimeInput, UnsavedChangesDialog},
    mixins: [validationRules],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        requestForm: {
            type: Object,
            default: () => ({})
        },
        editAll: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selectedShiftTimeId: null,
            shiftTimes: [],
            shiftTimesLoading: false,
            timePickerModal: false,
            shiftTime: {},
            startTimeKey: 1111,
            durationKey: 2222,
            customTokens: {
                'h': {pattern: /[0-3]/},
                'H': {pattern: /[0-9]/},
                'm': {pattern: /[0-5]/},
                'M': {pattern: /[0-9]/}
            },
            isEditing: false,
            matchAgainst: {
                selectedShiftTimeId: null,
                shiftTime: {},
            },
            innerWidth: window.innerWidth,
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        formattedStartTime() {
            if (typeof this.shiftTime.startTime === 'undefined' || !this.shiftTime.startTime) {
                return ''
            }

            let time = moment(moment().format('YYYY-MM-DD') + ' ' + this.shiftTime.startTime)
            return time.format('h:mm A')
        },
        start_time() {
            return typeof this.shiftTime.startTime !== 'undefined' ? this.shiftTime.startTime : null
        },
        duration() {
            return typeof this.shiftTime.duration !== 'undefined' ? this.shiftTime.duration : null
        },
        start_date_time() {
            if (!this.start_time) {
                return null
            }

            return moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time)
        },
        end_date_time() {
            if (!this.start_date_time || !this.duration) {
                return null
            }

            return this.start_date_time.clone().add(this.duration, 'm')
        },
        end_time() {
            if (!this.end_date_time) {
                return null
            }

            return this.end_date_time.format('h:mm A')
        },
        end_time_hint() {
            if (!this.start_time) {
                return 'Set a start time'
            }

            if (!this.duration) {
                return 'Set a duration'
            }

            if (!this.end_date_time.isSame(this.start_date_time, 'd')) {
                let diff = this.end_date_time.clone().startOf('day').diff(this.start_date_time.clone().startOf('day'), 'd')
                return diff > 0 ? ('+ ' + diff + ' ' + this.$options.filters.pluralize('Day', diff)) : ''
            }

            return ''
        },
        globalLocation() {
            return this.$root.globalLocation
        },
        globalTimezone() {
            return this.$root.globalTimezone
        },
        editAutomatedShiftFormRef() {
            return this.$refs?.editAutomatedShiftForm
        },
        isDirty() {
            return !_.isEqual({
                selectedShiftTimeId: this.selectedShiftTimeId,
                shiftTime: this.shiftTime,
            }, this.matchAgainst)
        },
        unsavedChangesDialogRef() {
            return this.$refs.unsavedChangesDialogRef
        },
        rhsContainerStyle() {
            return {
                ...this.innerWidth >= 960 && {
                    'border-left': '1px solid #000000'
                }
            }
        }
    },
    methods: {
        initStaffingAutomation() {
            const selectedShiftTimeId = this.requestForm.shift_time_id ?? null
            const shiftTime = {
                startTime: moment(this.requestForm.start).tz(this.requestForm.timezone).format('HH:mm'),
                duration: this.requestForm.duration
            }
            this.selectedShiftTimeId = selectedShiftTimeId
            this.shiftTime = {...shiftTime}
            this.matchAgainst = {
                selectedShiftTimeId,
                shiftTime: {...shiftTime},
            }
        },
        fetchShiftTimes() {
            this.shiftTimesLoading = true
            this.api.get('/staffing-automation/modal-options-data/shift_time')
                .then(data => {
                    const shiftTimes = data?.data
                    if (Array.isArray(shiftTimes)) {
                        this.shiftTimes = Array.from(shiftTimes)
                    }
                })
                .catch(console.log)
                .finally(() => {
                    this.shiftTimesLoading = false
                })
        },
        onChangeShiftTime(shiftTimeId) {
            if (shiftTimeId) {
                const shiftTime = this.shiftTimes.find(shiftTime => shiftTime._id === shiftTimeId)
                if (shiftTime) {
                    this.setTimes(shiftTime.start_time, shiftTime.duration)
                }
            } else {
                this.setTimes(null, null)
            }
        },
        onConfirmStartTime() {
            this.$refs.timePickerDialog.save(this.shiftTime.startTime)
            this.selectedShiftTimeId = null
        },
        onChangeDuration() {
            this.selectedShiftTimeId = null
        },
        setTimes(startTime, duration) {
            this.shiftTime = {
                startTime,
                duration,
            }
            this.startTimeKey = this.startTimeKey + 1
            this.durationKey = this.durationKey + 1
        },
        close() {
            this.modalValue = false
        },
        onClose() {
            if (this.isDirty) {
                return this.unsavedChangesDialogRef.open()
                    .then(() => {
                        this.close()
                    })
                    .catch(() => {
                    })

            } else {
                this.close()
            }
        },
        onSave() {
            if (!this.validateForm()) {
                return
            }
            this.isEditing = true
            this.api.post(`/request-forms/edit-staffing-automation-shifts/${this.requestForm._id}`, {
                start_time: this.start_time,
                duration: this.duration,
                shift_time_id: this.selectedShiftTimeId,
                edit_all: this.editAll
            })
                .then(({data, message}) => {
                    message('Automated Shifts Updated')
                    this.$emit('onUpdate', data)
                    this.close()
                })
                .catch(console.log)
                .finally(() => {
                    this.isEditing = false
                })
        },
        validateForm() {
            if (this.editAutomatedShiftFormRef) {
                return this.editAutomatedShiftFormRef.validate()
            }
            return false
        },
        onResize() {
            this.innerWidth = window.innerWidth
        },
    },
    created() {
        this.initStaffingAutomation()
        this.fetchShiftTimes()
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
}
</script>

<style scoped>
.row-min-width {
    min-width: 250px;
}
</style>
